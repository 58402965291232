<script setup lang="ts">
import { computed } from 'vue';

import { LanguageType } from '@/__generated__/gateway/graphql';
import RelativeTime from '@/components/community/RelativeTime.vue';
import { useGatewayNotifications } from '@/shared/composables/notifications';
import { getLanguageType } from '@/shared/utils/gateway/helpers';

import Avatar from '../UI/Avatar.vue';

type Notification = (typeof notifications.value)[0];

const props = defineProps<{
  notification: Notification;
}>();

const { processing, notifications, markAsRead, generateNotificationUrl } =
  useGatewayNotifications(
    getLanguageType(window.Shopify.locale, LanguageType.En)
  );

const targetUrl = computed(() => generateNotificationUrl(props.notification));

const markAsReadOnClick = async (notification: Notification) => {
  if (!notification.read_at) {
    await markAsRead(notification.id);
  }
};

const isRead = computed(() => !!props.notification.read_at);

const message = computed(() =>
  props.notification.message.replace(
    props.notification.actor.display_name,
    `<strong>${props.notification.actor.display_name}</strong>`
  )
);
</script>

<template>
  <a
    class="group my-1 flex cursor-pointer rounded bg-white px-2 py-1 transition-all duration-300 hover:bg-gray-lightest hover:no-underline"
    :class="{ read: isRead }"
    :disabled="processing"
    :href="targetUrl"
    @click.left="markAsReadOnClick(notification)"
    @click.middle.exact="markAsReadOnClick(notification)"
  >
    <Avatar :customer="notification.actor" />
    <div class="flex-auto px-4 leading-4">
      <!-- eslint-disable vue/no-v-html -- We always know where the content comes from (our own API) so this should be fine -->
      <div
        class="text-base leading-5 text-gray-darkest transition-colors duration-300 group-[.read]:text-gray-500"
        v-html="message"
      ></div>
      <RelativeTime
        :date="notification.created_at"
        class="text-xs leading-4 text-gray-500"
      />
    </div>
    <div
      class="flex w-3 items-center before:block before:size-3 before:rounded-full before:bg-primary before:transition-opacity before:duration-300 before:content-[''] group-[.read]:before:opacity-0"
    ></div>
  </a>
</template>
