<script setup lang="ts">
import { ButtonIcon, IconX } from '@hobbii/hobbii-ui';
import { computed, ref } from 'vue';

import ButtonText from '@/shared/components/UI/Buttons/ButtonText.vue';
import Modal from '@/shared/components/UI/Modal.vue';
import { useCommunityTosMutations } from '@/shared/composables/community/tos';
import { useTranslations } from '@/shared/composables/useTranslations';

import { useUserStore } from '../../pinia/modules/user';

const { t } = useTranslations();

const userStore = useUserStore();

const { acceptTos, processing } = useCommunityTosMutations();

const tocAgreed = ref(false);

const onContinueClick = async () => {
  try {
    // this should not be callable without checking the box
    if (tocAgreed.value) {
      await acceptTos();
      userStore.setTermsOfServiceAgreement(tocAgreed.value);
    }
  } catch (err) {
    // TODO: How to handle errors here?
    console.error(err);
    closeModal();
  }
};

const closeModal = () => {
  userStore.closeTermsOfServiceAgreementModal();
};

const showModal = computed({
  get() {
    return userStore.showingTermsOfServiceAgreementModal;
  },
  set(value) {
    if (!value) {
      userStore.closeTermsOfServiceAgreementModal();
    }
  }
});
</script>

<template>
  <Modal
    v-model:active="showModal"
    size="sm"
    :close-button="false"
    :close-on-escape-key="true"
    :close-on-click-outside="false"
    :always-on-top="true"
  >
    <div
      class="relative flex flex-col gap-3 bg-gradient-to-b from-primary-100 px-4 pt-4"
    >
      <img
        src="@/images/disclaimer/swirwl.svg"
        alt=""
        class="absolute right-0 top-0"
      />
      <ButtonIcon
        class="absolute right-2 top-2"
        theme="light"
        aria-label="Close"
        label="Close"
        @click="closeModal"
        ><IconX class="text-xl"></IconX
      ></ButtonIcon>
      <h1 class="mt-4 text-2xl font-bold">
        {{ t('toc.text_title') }}
      </h1>
      <p>{{ t('toc.text_description') }}</p>

      <div class="flex gap-2">
        <input
          id="toc-agree"
          v-model="tocAgreed"
          type="checkbox"
          class="relative size-6 cursor-pointer appearance-none items-start rounded-md text-primary accent-primary focus:outline-primary active:outline-primary"
        />
        <i18n-t
          keypath="toc.text_agree"
          tag="label"
          for="toc-agree"
          class="flex-1 cursor-pointer"
        >
          <a
            class="underline"
            href="/pages/membership-conditions"
            target="_blank"
            >{{ t('toc.label') }}</a
          >
        </i18n-t>
      </div>

      <ButtonText
        theme="primary"
        size="lg"
        class="!text-lg"
        :disabled="processing || !tocAgreed"
        @click="onContinueClick"
      >
        {{ t('toc.text_continue') }}
      </ButtonText>

      <div
        class="ease relative mt-1 size-full overflow-hidden transition-all duration-200"
      >
        <div class="relative mx-auto w-[65px] -translate-x-2">
          <img
            src="@/images/disclaimer/arm.png"
            alt=""
            class="absolute left-[25px] top-0 w-[80px] max-w-none origin-[left_80%] rotate-90 transition-all duration-200 ease-[cubic-bezier(0.25,0.1,0.38,1.8)]"
            :class="[
              tocAgreed ? 'rotate-[20deg] opacity-100' : 'rotate-90 opacity-0'
            ]"
            width="94"
            height="79"
          />

          <img
            src="@/images/disclaimer/fbi_no_arms.png"
            alt=""
            class="relative z-0 h-auto w-full transition-transform duration-100"
            width="65"
            height="70"
          />

          <img
            src="@/images/disclaimer/smile.png"
            alt=""
            class="absolute left-[42%] top-[34px] w-[13px] transition-[all_0.2s_cubic-bezier(0.25,0.1,0.38,1.46)] duration-100"
            :class="[tocAgreed ? 'rotate-[-16deg] delay-75' : 'rotate-[10deg]']"
            width="15"
            height="10"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>
