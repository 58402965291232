import { useMutation } from '@vue/apollo-composable';
import { computed } from 'vue';

import { acceptTosMutation } from '@/shared/graphql/gateway/community/mutations';

export const useCommunityTosMutations = () => {
  const { mutate: dispatchAcceptTos, loading } = useMutation(
    acceptTosMutation,
    {
      clientId: 'gateway',
      fetchPolicy: 'network-only'
    }
  );

  const acceptTos = async () => {
    const res = await dispatchAcceptTos({
      alias: 'community'
    });

    if (res?.errors?.length) {
      throw res.errors[0];
    }

    return res?.data?.toc?.accept?.success ?? false;
  };

  const processing = computed(() => loading.value);

  return {
    processing,
    acceptTos
  };
};
