import { v5 as uuid5 } from 'uuid';

import { userCookies } from '@/shared/constants/cookies';

import { getCookie, setCookie } from '../utils/cookie';

const HOBBII_NAMESPACE = '64525bc0-03a1-4d1f-b342-1f53a5061697';
const hobbiiStoreData = window.Hobbii?.hobbiiStoreData;
const initGtmTracking = () => {
  updateCurrencyRateCookie();
  updateContactUuidCookie();
};

function updateContactUuidCookie() {
  if (hobbiiStoreData?.isLogged && hobbiiStoreData.customer?.email) {
    saveContactUuidCookie(hobbiiStoreData.customer?.email);
  } else if (getCookie(userCookies.CONTACT_UUID)) {
    setCookie(userCookies.CONTACT_UUID, '', -1);
  }
}

function saveContactUuidCookie(email: string) {
  if (!email) return;

  // This cookie is read by GTM and used in Emarsys conversion tracking
  setCookie(userCookies.CONTACT_UUID, generateContactUuid(email));
}

function generateContactUuid(email: string) {
  return uuid5(email, HOBBII_NAMESPACE);
}

function updateCurrencyRateCookie() {
  if (hobbiiStoreData) {
    saveCurrencyRateCookie(hobbiiStoreData.currencyRate.toString());
  }
}

function saveCurrencyRateCookie(currencyRate: string) {
  if (!currencyRate) return;

  // This cookie is read by GTM and used in Emarsys conversion tracking
  setCookie(userCookies.CURRENCY_RATE, currencyRate.toString());
}

export { initGtmTracking };
