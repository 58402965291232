import { App } from 'vue';

import { createCoreVueApp } from '@/entrypoints/shared/core-vue-app';

import TocVerification from '../components/Toc/TocVerification.vue';
import GlobalKeyDownDirective from '../directives/globalKeyDown';

const mountSelector = '#toc-verification';

export const initTocVerification = async () => {
  if (window.location.pathname.startsWith('/cart')) {
    return;
  }

  const appDecorator = (app: App) => {
    // TODO: remove global-keydown directive once all components are self contained.
    app.directive('global-keydown', GlobalKeyDownDirective);
  };

  const coreApp = await createCoreVueApp(
    TocVerification,
    mountSelector,
    {},
    appDecorator
  );

  coreApp.mount(mountSelector);
};
