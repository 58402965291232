import { App } from 'vue';

import { mountCommunityComponentAsApp } from '@/entrypoints/shared/community-mounter';
import Notifications from '@/shared/components/Notifications/Notifications.vue';
import GlobalKeyDownDirective from '@/shared/directives/globalKeyDown';
import { useUserStore } from '@/shared/pinia/modules/user';

export const initNotifications = async () => {
  if (window.location.pathname.startsWith('/cart')) {
    return;
  }

  const userStore = useUserStore();
  if (!userStore.isLogged) {
    return;
  }

  const mountSelector = '#notifications-app';
  const appDecorator = (app: App) => {
    app.directive('global-keydown', GlobalKeyDownDirective);
  };

  mountCommunityComponentAsApp(Notifications, mountSelector, {}, appDecorator);
};
