import { hobbiiSessionStorage } from '@/shared/utils/web-storage';

import { isEmailValid } from '../utils/email';

export const initNewsletterSignupForm = async () => {
  const signupSection = document.getElementById('newsletter-signup-section');
  const signupForm = document.querySelector<HTMLFormElement>(
    '#newsletter-signup-form'
  );
  const emailInput = signupForm?.querySelector<HTMLInputElement>(
    'input[type="email"]'
  );
  const submitBtn =
    signupForm?.querySelector<HTMLButtonElement>('[type="submit"]');
  const alertError =
    signupSection?.querySelector<HTMLElement>('.js-alert-error');
  const alertErrorList = signupSection?.querySelector<HTMLUListElement>(
    '.js-alert-error-list'
  );

  emailInput?.addEventListener('blur', validateEmailInput);

  emailInput?.addEventListener('input', validateEmailInput);

  signupForm?.addEventListener('submit', function () {
    hobbiiSessionStorage.setItem('signupForm', 'newsletter-signup-form');
    if (submitBtn) submitBtn.disabled = true;
    return true;
  });

  let emailValid = true;
  function validateEmailInput(this: HTMLInputElement, e: Event) {
    // if user has typed invalid number before, use validation on keyup else: only validate on blur
    if (e.type === 'input' && emailValid === true) {
      return;
    }

    const email = this?.value.trim();

    // Hide error when field is empty
    if (!email) {
      if (signupForm) removeFormError(signupForm);
      if (submitBtn) submitBtn.disabled = true;
      hideErrors();
    } else if (isEmailValid(email)) {
      emailValid = true;
      if (signupForm) removeFormError(signupForm);
      if (submitBtn) submitBtn.disabled = false;
      hideErrors();
    } else {
      emailValid = false;
      setInputErrorState(this);
      if (submitBtn) submitBtn.disabled = true;
      if (this.dataset.errorMessage) showErrors([this.dataset.errorMessage]);
    }
  }

  function removeFormError(form: HTMLFormElement) {
    form
      .querySelectorAll('input')
      .forEach((input) => removeInputErrorState(input));
  }

  function setInputErrorState(input: HTMLInputElement) {
    input.classList.add('border-error-200');
    input.classList.add('bg-error-100');
    input.classList.add('text-error-800');
  }

  function removeInputErrorState(input: HTMLInputElement) {
    input.classList.remove('border-error-200');
    input.classList.remove('bg-error-100');
    input.classList.remove('text-error-800');
  }

  function showErrors(messages: string[]) {
    if (!alertErrorList || !alertError)
      throw new Error('Error elements not found in DOM');

    alertErrorList.innerHTML = '';

    messages.forEach(function (message) {
      const li = document.createElement('li');
      li.textContent = message;
      alertErrorList.appendChild(li);
    });

    // Show alert
    alertError.classList.remove('hidden');
  }

  function hideErrors() {
    if (!alertErrorList || !alertError)
      throw new Error('Error elements not found in DOM');

    alertError?.classList.add('hidden');
    alertErrorList.innerHTML = '';
  }
};
