<template>
  <form
    ref="searchContainer"
    action="/search"
    novalidate
    role="search"
    @submit="goToProductPageSearch()"
  >
    <div
      class="flex h-searchBarMobile rounded-md border border-gray ring-1 ring-primary/5 transition-all ease-linear placeholder:text-gray-400 focus-within:!border-primary/80 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-200 hover:border hover:border-gray-300 lg:h-searchBar focus-within:lg:ring-4"
    >
      <!--
        Using @input event instead of v-model due to crazy android quirk
        See explanation from Evan You https://github.com/vuejs/vue/issues/9777#issuecomment-478831263
      -->
      <input
        :id="inputId"
        ref="inputEl"
        class="size-full flex-1 appearance-none rounded-l-md border-0 pl-base focus:ring-0"
        autocomplete="off"
        autocorrect="off"
        :value="search"
        name="q"
        autocapitalize="none"
        enterkeyhint="search"
        spellcheck="false"
        :placeholder="t('search.search_placeholder')"
        maxlength="512"
        type="text"
        @input="onInput"
        @keydown.escape="onEscape"
        @focus="hidePanel = false"
      />
      <button
        v-if="search"
        class="js-header-search-clear flex h-full w-[34px] items-center justify-center text-gray-400 hover:text-gray-darker lg:w-[54px]"
        type="reset"
        :title="t('search.search_clear_title')"
        @click="clearSearch"
      >
        <IconClear class="size-[20px]" />
      </button>
      <button
        id="header-searchbox-submit"
        class="flex h-full w-[54px] items-center justify-center rounded-r-md lg:w-[54px]"
        type="submit"
        :title="t('search.search_button_title')"
      >
        <IconSearch
          class="size-[20px] stroke-gray-darker stroke-0 text-gray-darker"
        />
      </button>
    </div>
  </form>
  <div class="flex gap-0 lg:relative">
    <Teleport :disabled="!isTabletOrLowerView" to="body">
      <div
        v-if="isPanelOpen"
        :style="panelStyles"
        :class="panelClasses"
        class="z-aa-panel"
      >
        <div
          class="js-header-search-scroll-container relative overflow-y-auto rounded-md border bg-white max-lg:size-full lg:inset-x-0 lg:bottom-auto lg:w-[700px] lg:overflow-hidden lg:shadow-lg xl:w-[800px]"
          @mouseover="isPanelInteraction = true"
          @mouseleave="isPanelInteraction = false"
          @touchstart="isPanelInteraction = true"
          @touchend="nextTick(() => (isPanelInteraction = false))"
        >
          <div class="flex w-full">
            <template v-if="!hasResults">
              <div
                class="flex grow animate-appear items-center justify-center p-8 text-lg"
              >
                &nbsp;<IconSpinnerFast class="text-xl text-primary-700" />&nbsp;
              </div>
            </template>

            <div v-else class="grow">
              <div class="transition-opacity">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="resultsHTML"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import pDebounce from 'p-debounce';
import {
  computed,
  CSSProperties,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue';

import IconClear from '@/shared/components/UI/Icons/IconClear.vue';
import IconSearch from '@/shared/components/UI/Icons/IconSearch.vue';
import IconSpinnerFast from '@/shared/components/UI/Icons/IconSpinnerFast.vue';
import { useTranslations } from '@/shared/composables/useTranslations.ts';
import { useViewport } from '@/shared/composables/useViewport.js';
import {
  lockAllParentsScroll,
  unlockAllLockedElementsScroll,
  unlockAllParentsScroll
} from '@/shared/utils/scroll/scroll.ts';

import { useConfigStore } from '../../pinia/modules/config.js';

const { t } = useTranslations();

const props = defineProps({
  // Initial search input (from request)
  searchTerms: {
    type: String,
    default: ''
  },
  inputId: {
    type: String,
    default: undefined
  }
});

const configStore = useConfigStore();

const { isTablet, isMobile } = useViewport();
const isTabletOrLowerView = computed(() => isTablet.value || isMobile.value);

watch(isTabletOrLowerView, (newVal) => {
  if (!newVal) {
    unlockAllLockedElementsScroll();
  }
});

const search = ref(null as string | null);
const resultsHTML = ref('');
const inputEl = ref<HTMLInputElement | null>(null);
const panelStyles = ref<CSSProperties>({});
const searchContainer = ref<HTMLElement | null>(null);
const isPanelInteraction = ref(false);
const hidePanel = ref(false); // Used to hide search panel on initial load in case there is an active search term in the query
const destroyAutocompleteFn = ref(() => {});

const searchScrollContainerSelector = '.js-header-search-scroll-container';
const selectSearchContainer = () =>
  document.querySelector<HTMLDivElement>(searchScrollContainerSelector);

onClickOutside(searchContainer, () => (hidePanel.value = true));

const onInput = (e: Event) => {
  const target = e.target as HTMLInputElement | null;
  if (target) {
    search.value = target.value;
    hidePanel.value = false;
  }
};

const collapseKeyboardOnTouchMove = () => inputEl.value?.blur();

const onEscape = () => {
  hidePanel.value = true;
  inputEl.value?.blur();
};

const clearSearch = () => (search.value = '');

const executeSearch = async (query: string | null) => {
  resultsHTML.value = '';

  if (!query || query.length < configStore.minSearch) {
    return;
  }

  const res = await fetch(
    `${window.Shopify.routes.root}search/suggest?q=${encodeURIComponent(
      query
    )}&resources[type]=product&resources[limit_scope]=each&resources[options][unavailable_products]=last&resources[options][fields]=title,product_type,body&section_id=predictive-search`
  );

  const data = await res.text();

  if (!res.ok) {
    throw new Error(`${res.status}: ${data}`);
  }

  resultsHTML.value =
    new DOMParser()
      .parseFromString(data, 'text/html')
      // Shopify generated id for results #shopify-section-predictive-search
      .querySelector('#shopify-section-predictive-search')?.innerHTML ?? '';
};

const debouncedSearch = pDebounce(executeSearch, 500);

watch(search, debouncedSearch);

const hasResults = computed(
  () => resultsHTML.value && resultsHTML.value.trim().length
);

onMounted(() => {
  // Prefill search from query
  if (props.searchTerms) {
    hidePanel.value = true;
    search.value = props.searchTerms;
  }
});

// Open panel on valid searches
const isPanelOpen = computed(
  () => !hidePanel.value && (search.value?.length ?? 0) >= configStore.minSearch
);

const panelClasses = computed(() => {
  if (isTabletOrLowerView.value) {
    return 'fixed bottom-0 w-full left-0 pt-2 px-4 -mt-1 lg:mt-0 lg:px-0 lg:absolute lg:w-auto lg:right-0';
  } else {
    return 'absolute bottom-0 left-0 pt-2 translate-y-full';
  }
});

const goToProductPageSearch = () => {
  const resultsPageURL = new URL('/search', window.location.href);
  resultsPageURL.searchParams.set('q', search.value ?? '');
  resultsPageURL.searchParams.set('type', 'product');
  window.location.href = resultsPageURL.toString();
};

watch(isPanelOpen, (isOpen) => {
  if (!isTabletOrLowerView.value) {
    return;
  }

  if (isOpen) {
    // Set initial panel position when opening search results
    setPanelPositionMobile();
    nextTick(() => {
      lockAllParentsScroll(selectSearchContainer());
      window.addEventListener('touchmove', collapseKeyboardOnTouchMove);
    });
    return;
  }

  window.removeEventListener('touchmove', collapseKeyboardOnTouchMove);
  unlockAllParentsScroll(selectSearchContainer());
});

// Manage resize- and scroll listeners for mobile layout
watch(isTabletOrLowerView, (newVal) => {
  if (newVal) {
    window.addEventListener('scroll', setPanelPositionDebounced);
    window.addEventListener('resize', setPanelPositionDebounced);
    // Reposition of the autocomplete if the user exits mobile browser and refocuses window
    document.addEventListener('focus', setPanelPositionDebounced);
  } else {
    window.removeEventListener('scroll', setPanelPositionDebounced);
    window.removeEventListener('resize', setPanelPositionDebounced);
    document.removeEventListener('focus', setPanelPositionDebounced);
  }
});

// Set panel position for fixed position
const setPanelPositionMobile = () => {
  // Ensure we won't adjust position when on desktop or if panel is hidden
  if (
    !isTabletOrLowerView.value ||
    !isPanelOpen.value ||
    !searchContainer.value
  )
    return;

  const containerRect = searchContainer.value.getBoundingClientRect();
  panelStyles.value.top = `${containerRect.bottom}px`;
};

const setPanelPositionDebounced = pDebounce(setPanelPositionMobile, 200);

onUnmounted(() => {
  unlockAllLockedElementsScroll();
  destroyAutocompleteFn.value();
  window.removeEventListener('scroll', setPanelPositionDebounced);
  window.removeEventListener('resize', setPanelPositionDebounced);
  document.removeEventListener('focus', setPanelPositionDebounced);
});
</script>
