<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from 'vue';

import { LanguageType } from '@/__generated__/gateway/graphql';
import { useGatewayNotifications } from '@/shared/composables/notifications';
import { useTranslations } from '@/shared/composables/useTranslations';
import { getLanguageType } from '@/shared/utils/gateway/helpers';

import { debounce } from '../../utils/functions';
import NotificationItem from './NotificationItem.vue';

const {
  loading,
  notifications,
  error,
  unreadIds,
  unseen,
  hasMore,
  refetch,
  loadMore,
  markAllAsSeen,
  markAsRead
} = useGatewayNotifications(
  getLanguageType(window.Shopify.locale, LanguageType.En)
);

const { t } = useTranslations();

const notificationsContainer = ref<HTMLElement | null>(null);
const notificationsList = ref<HTMLElement | null>(null);
const showLoader = computed(() => loading.value);
const showLoaderSpace = computed(() => hasMore.value);

onMounted(() => {
  notificationsContainer.value?.addEventListener('scroll', onScroll);

  refetch();

  if (unseen.value > 0) {
    markAllAsSeen();
  }
});

// TODO: introduce new load-more solution?
const onScroll = debounce(() => {
  const boundingClientRect = notificationsList.value?.getBoundingClientRect();
  if ((boundingClientRect?.bottom ?? 0) - window.innerHeight < 200) {
    showMore();
  }
}, 300);

watch(notifications, () => {
  nextTick(() => {
    const containerOffsetHeight = notificationsContainer.value?.offsetHeight;
    const listOffsetHeight = notificationsList.value?.offsetHeight;
    if (
      listOffsetHeight &&
      containerOffsetHeight &&
      listOffsetHeight - containerOffsetHeight < -10
    ) {
      showMore();
    }
  });
});

const showMore = () => {
  loadMore();
};

const onMarkAllAsReadClick = async () => {
  await markAsRead(unreadIds.value);
};

onBeforeUnmount(() => {
  notificationsContainer.value?.removeEventListener('scroll', onScroll);
});
</script>

<template>
  <div
    ref="notificationsContainer"
    class="relative h-[calc(100vh-64px)] overflow-auto"
    :class="{ 'pb-[90px]': showLoaderSpace }"
  >
    <Transition name="slide-fade">
      <div v-if="unreadIds.length" class="max-h-[39px] text-right">
        <span
          class="inline-block cursor-pointer px-4 py-2 text-xs font-bold text-primary"
          @click="onMarkAllAsReadClick"
          >{{ t('community.notifications.text_mark_as_read') }}</span
        >
      </div>
    </Transition>
    <div v-if="error" class="text-error">
      <b>{{ t('global.error') }}:</b> {{ error.message }}
    </div>
    <div
      v-if="notifications.length"
      ref="notificationsList"
      class="px-2.5 pb-1 pt-0"
    >
      <NotificationItem
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </div>
    <div
      v-if="!notifications.length && !showLoader"
      class="px-4 py-12 text-center"
    >
      {{ t('community.notifications.text_no_notifications') }}
    </div>
    <div v-if="showLoader" class="loader absolute left-1/2 -ml-6"></div>
  </div>
</template>
